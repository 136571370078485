.lds-spinner {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    margin: auto;
    text-align: center;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}
.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #bababa;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* --------------------------------------------------------- */
/* MODAL ANIMATION */
/* --------------------------------------------------------- */

.main-modal {
    position: relative;
    width: 376px;
    margin: auto;
    text-align: center;
}

.wa-cont {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
}

.wa-main {
    position: relative;
    height: 445px;
}
.wa-img p, a.wa-btn{
    text-align: center;
}

.wa-cont p, .wa-img p {
    padding: 0 20px;
}

a.wa-btn {
    position: absolute;
    left: 0;
    bottom: 30px;
    right: 0;
    background: #00e676;
    width: 200px;
    margin: auto;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 13px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
}
.send-msj{
    animation: fadein-step2 1s linear forwards;
    animation-delay: 5s;
    opacity: 0;
}

.wa-img {
    animation: fadeinout 0.5s linear forwards;
    position: relative;
    animation-delay: 4s;
}
.step1{
    animation: nextstep 0.5s ease-in-out forwards;
    animation-delay: 4s;
    position: absolute;

}
.step2{
    animation: nextstep 0.5s ease-in-out forwards;
    animation-delay: 8s;
    position: absolute;
    // display: none;
    left: 0;
    right: 0;
}

  a.wa-btn {
    animation: scaleimg 0.5s;
    animation-delay: 2.5s;
  }

  figure.send-btn {
    animation: scaleimg 0.5s;
    animation-delay: 8s;
    position: absolute !important;
    right: 83px;
    bottom: 18px;
    width: 32px;
    z-index: 1;
    padding: 0;
    border: none;
  }


@keyframes fadeinout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadein-step2 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
  @keyframes nextstep {
    0% {
        display: none;
    }
    100% {
        display: block;
    }
}
@keyframes scaleimg {
    0%   {
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);

     }
    50%  {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
     }
     100%   {
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);

     }
}
@media only screen and (max-width: 414px) {
    figure.send-btn {
        right: 8%;
      }
}
@media only screen and (max-width: 320px) {
    figure.send-btn {
        right: 6%;
      }
}
