@charset "utf-8";
.demo {
    .nav {
        background: #424242;
        h1#logo a {
            background: url(/images/logos/appetit-logo.svg) center center no-repeat;
            background-size: 95px auto;
            width: 95px;
            height: 35px;
            margin-top: 7px;
        }
    }
    #shopping-cart-button #nav-button .badge {
        border: 2px solid #fff;
    }
    @media only screen and (max-width: 480px) {
        .nav .container {
            height: 60px !important;
        }
        div.wrapper {
            margin-top: 60px;
        }
        .section.header-section {
            background-position: right 0 top 60px;
        }
        #shopping-cart-button #nav-button {
            padding-top: 0px;
            span.badge {
                top: -11px;
            }
        }
    }
}
