@charset "utf-8";
/* CSS Document */

/*------------------------------------------------------------------
[Table of contents]
0. Variables
1. Clearfix
3. Typography
4. Headings
5. Main Nav
6. Main Content
7. Column Grid
8. Content Elements
9. Icons
11. Footer
12. Lists
13. Google Maps
15. Btns
16. Modal
17. Modifiers
-------------------------------------------------------------------*/
.scroll-disabled {
    overflow: hidden;
}

/* =1. Clearfix
--------------------------------------------------------------------------------------------------------*/
.clear {
    clear: both;
}
.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
} /* ----- clear fix for floats ----- */
* html .clearfix {
    height: 1%;
} /* hides clearfix from IE-mac */

.hidden {
    display: none !important;
    visibility: hidden;
}
/* End Clearfix
--------------------------------------------------------------------------------------------------------*/

/* =3. Typography
--------------------------------------------------------------------------------------------------------*/
html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    height: 100%;
    overflow-x: hidden;
}
body {
    font-size: 16px;
    line-height: 1.5;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    color: #3b3b3b;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
strong {
    font-weight: 600;
    color: #3b3b3b;
}
a,
a > * {
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}
a,
a:hover,
a:focus {
    color: #3b3b3b;
    text-decoration: none;
    outline: 0;
}
*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-rendering: optimizeLegibility;
    outline: 0;
}
*:focus,
*:after:focus,
*:before:focus {
    outline: 0;
}
*:after,
*:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

p {
    line-height: 1.5;
    padding: 0;
    color: #3b3b3b;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    position: relative;
}
p a,
dl a {
    color: #3b3b3b;
    text-decoration: none;
}
p.small {
    font-style: italic;
}
p.uppercase {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
}
.parent p.uppercase {
    margin: 0;
}
p.uppercase.black {
    color: #3b3b3b;
}

dd,
dt {
    line-height: 1.6;
}
p.sub-heading {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 50px;
    line-height: 1.5;
}

.child p.small {
    margin: 10px 0;
}

.header-bottom p {
    font-size: 13px;
}
.child {
    text-align: center;
}
/* vertical centering */
.parent {
    display: table;
}
.child {
    display: table-cell;
    vertical-align: middle;
    padding: 0 50px;
}
p.large {
    font-size: 26px;
    padding-bottom: 20px;
    line-height: 1.3;
    font-weight: 300;
    letter-spacing: -1px;
}
.centered p.sub-heading,
.centered p.large {
    display: inline-block;
    max-width: 524px;
}
p.large.above-button {
    padding-bottom: 0;
}
p.price {
    font-size: 18px;
}
.button,
.button:focus,
.send-btn,
.send-btn:focus {
    display: block;
    color: #3b3b3b;
    border: solid 2px #3b3b3b;
    padding: 12px 30px 13px;
    margin: 10px 0 10px 0;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 1px;
    display: inline-block;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    z-index: 1;
    position: relative;
    -webkit-text-shadow: none !important;
    text-shadow: none !important;
    font-style: normal;
}
i.white-fa-cart {
    color: #fff !important;
    margin-right: 10px !important;
}
.centered .button {
    margin-left: 5px;
    margin-right: 5px;
}
.button:hover,
.send-btn:hover {
    background: #3b3b3b;
    color: #fff;
}

.button i {
    margin-right: 3px;
}
.button:hover i {
    color: #fff;
}

.dark,
.dark p.sub-heading,
.dark p.uppercase {
    color: #fff;
}
.dark p.uppercase {
    opacity: 0.7;
    font-size: 13px;
}

a.link,
p a,
ul.unordered-list li a,
ul.contact-list li a {
    position: relative;
    color: #3b3b3b;
}
a.link::after,
p a::after,
ul.unordered-list li a::after,
ul.contact-list li a::after {
    content: '';
    background: #3b3b3b;
    bottom: -1px;
    display: block;
    left: 50%;
    height: 1px;
    position: absolute;
    -webkit-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    width: 0;
}
a.link:hover::after,
p a:hover::after,
ul.unordered-list li a:hover::after,
ul.contact-list li a:hover::after {
    left: 0;
    width: 100%;
    color: #3b3b3b;
}
/* End Typography
--------------------------------------------------------------------------------------------------------*/

/* =4. Headings
--------------------------------------------------------------------------------------------------------*/
h1#logo {
    display: inline-block;
    margin: 0;
    width: auto;
    height: auto;
    z-index: 200;
    padding: 19px 15px;
    font-size: 0;
    line-height: 1;
}
h1#logo a {
    display: block;
    background: url(/images/logos/la-fortuna-pub.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 62px 62px;
    width: 62px;
    height: 62px;
    text-indent: -9999px;
    cursor: pointer;
}
.tagline {
    float: left;
    font-size: 13px;
    font-weight: 400;
    position: relative;
    display: table;
    height: 52px;
    margin-top: -1px;
    color: #fff;
}
.tagline span {
    vertical-align: middle;
    display: table-cell;
}
h1,
h2,
h3,
h4,
h5 {
    line-height: 1.3;
    color: #3b3b3b;
    display: block;
    position: relative;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-weight: 400;
    z-index: 1;
}
.dark h1,
.dark h2,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h2 .small,
h3 .small,
h4 .small,
h5 .small {
    color: #fff;
}
h1 {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -1px;
    margin-top: 0px;
}
h2 {
    font-size: 22px;
    line-height: 1.25;
    letter-spacing: -1px;
    margin-top: 0px;
    font-weight: 700;
    display: inline-block;
    width: 100%;
}
h2#terms-of-service,
.terms-cond h1 {
    text-align: center;
    margin: 60px 0px 20px 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px;
}

.terms-cond h1 {
    margin-top: 0;
}

h3 {
    font-size: 26px;
    line-height: 1.3;
    margin-top: 10px;
    color: #363636;
    font-weight: 700;
    display: inline-block;
    width: 100%;
}
h4 {
    font-size: 24px;
    line-height: 1.35;
}
h5 {
    font-size: 19px;
    line-height: 1.4;
    font-weight: 700;
    margin-bottom: 0;
}
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover {
    color: #3b3b3b;
}

.header {
    margin-top: 5px !important;
}
.more-info h6,
.more-info h5,
.more-info h4,
.more-info h3 {
    margin-bottom: 5px;
}
.bordered-content h5,
.bordered-content h4,
.bordered-content h3 {
    margin-top: 0;
}
/* End Headings
--------------------------------------------------------------------------------------------------------*/

/* =5. Main Nav
--------------------------------------------------------------------------------------------------------*/
.nav {
    background-color: $backgroundColor;
    position: fixed;
    z-index: 1000;
    width: 100%;
    background-size: cover;
    &.nav-shadow {
        &:before {
            content: '';
            background: linear-gradient(to bottom, rgba(47, 47, 46, 0.8) 20%, rgba(47, 47, 46, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b32f2f2e', endColorstr='#0d2f2f2e',GradientType=0 ) !important;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
    span.label-rest-op {
        font-size: 15px;
        margin-right: 5px;
        color: var(--headerColor);
    }
    i.fa-angle-down {
        vertical-align: middle;
    }
}

.digital-menu .nav.nav-digital-menu {
    position: relative;
    text-align: center;
}

div.nav div.container {
    height: 60px;
}
div.wrapper {
    margin-top: 60px;
    min-height: calc(100vh - 280px);
}
.right-corner-wrapper {
    display: inline-block;
    float: right;
    transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
    transform: translate3d(0px, 0px, 0px);
    position: relative;
    z-index: 1;
}

#shopping-cart-button {
    font-size: 20px;
    display: block;
    height: 50px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 202;
    position: relative;
    float: right;
    margin-top: 9px;
}

#menu-button,
.navigation-bar {
    display: none;
}
#menu-button .centralizer {
    display: table-cell;
    vertical-align: middle;
}
#nav-button {
    display: inline-block;
    margin: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 18px 15px 0 15px;
    cursor: pointer;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}
.hide {
    display: none;
}
#shopping-cart-button #nav-button {
    padding-top: 5px;
    span.badge {
        background: #ff152f;
        color: #fff;
        border-radius: 15px;
        padding: 0.2em 0.45em;
        position: absolute;
        right: 0;
        top: 0;
        border: 2px solid #fff;
        font-size: 13px;
        transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
        transform: translate3d(0px, -40px, 0px);
        &.badge-active {
            transform: translate3d(4px, -4px, 0px);
        }
    }
}
a.mailto {
    color: #000;
}
.nav-bar {
    background-color: #3b3b3b;
    border-radius: 1px;
    display: block;
    width: 19px;
    height: 2px;
    margin: 3px 0 2px;
}
.nav-bar.second {
    width: 13px;
}
.nav-bar.third {
    width: 15px;
}
#shopping-cart-button #nav-button:hover {
    opacity: 0.7;
}
#main-nav {
    position: relative;
    padding-bottom: 90px;
}

#main-nav a span {
    display: inline-block;
    top: -5px;
    font-size: 11px;
    position: relative;
}
#main-nav > ul .active > a,
#main-nav > ul a.active,
#main-nav li a:hover,
#main-nav li a.selected {
    color: #3b3b3b;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}
#main-nav > ul > li > .sub-nav {
    padding: 14px 0px 14px 0;
    border-bottom: 1px solid #d8d8d8;
}
#main-nav > ul > li > .sub-nav > li > a {
    font-size: 13px;
    margin-right: 20px;
    padding: 4px 0px 4px;
    border: 0;
}
#main-nav li a:hover,
#main-nav > ul > li > .sub-nav > li > a:hover,
#main-nav > ul > li > a.active {
    background-position: 2px center;
    padding-left: 25px;
}
.pushed-left #nav-button span:nth-child(1),
.pushed-left-alt #nav-button span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    top: 5px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: 19px;
}
.pushed-left #nav-button span:nth-child(2),
.pushed-left-alt #nav-button span:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: 1px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 19px;
}
.pushed-left #nav-button span:nth-child(3),
.pushed-left-alt #nav-button span:nth-child(3) {
    display: none;
}

#social-header {
    display: block;
}

div.wrapper-digitalmenu {
    margin-top: 0;
    section.section-digitalmenu {
        padding-top: 0;
        background: #f8f8f8;
        section {
            padding-top: 0;
        }
        .categories-nav {
            padding: 5px 0 10px 0;
            background: #f8f8f8;
            display: none;
            div.container {
                width: 100%;
                overflow-x: scroll;
                display: block;
                padding: 0 15px;
                ul {
                    width: 100%;
                    margin-bottom: 0;
                    flex-direction: row;
                    li {
                        display: inline-block;
                        flex: 0 0 auto;
                        margin-right: 30px;
                        &:last-child{
                            padding-right: 30px;
                        }
                        a {
                            font-weight: 700;
                            padding: 10px;
                            position: relative;
                            display: block;
                            margin-top: 10px;
                            color: $primary;
                            border: 1px solid;
                            border-radius: 8px;
                        }
                        a.active {
                            color: #fff !important;
                            background: $primary;
                        }
                    }
                }
            }
        }
        .menu > div {
            width: 100%;
        }
        h3 {
            color: $secondary;
            margin-top: 20px;
        }
        .full-width {
            width: 100%;
            section.item-digitalmenu {
                // padding-top: 40px;
                .container {
                    padding: 0px;
                }
                .menu-item {
                    padding: 0;
                    border: none;
                    background: none;
                    &:hover{
                        box-shadow: none;
                        cursor: default;
                    }
                    p {
                        width: 100%;
                        text-align: left;
                    }
                    .row {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }
    }
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

div.nav{
    .content-header-title {
        float: left;
        padding-top: 14px;
        img {
            width: 25px;
            margin-bottom: 4px;
        }
        span.header-title {
            color: var(--headerColor);
            font-weight: 700;
            font-size: 18px;
            margin-left: 10px;
        }
        button.btn-back {
            svg{
                width: 25px;
                margin-top: -2px;
                g#Back-ico {
                    stroke: var(--headerColor);
                }
            }
            &:focus{
                outline: none;
            }
        }
    }
    i.fa{
        color: $headerColor;
    }
}
.item-modal {
    .menu-item {
        button.btn-success {
            width: inherit;
        }
    }
    .section.item-content {
        padding-bottom: 0;
    }
}
section.item-content {
    padding-top: 20px;
    div.quantity-item {
        margin: 30px auto 60px;
        input {
            background-color: transparent !important;
            border: none;
        }
        button.btn.btn-outline-secondary{
            border-radius: 0 20px 20px 0;
            border: none;
            padding: 1.25px .75rem;
            &:hover, &:active, &:focus {
                background-color: transparent;
                color: #2eb32c;
                box-shadow: none!important;
            }
            span.controls {
                font-size: 22px;
            }
        }
        .input-group.mb-3 {
            border: 1px solid #d1d1d1;
            border-radius: 20px;
        }
    }
}

.logo {
    height: 30px;
    margin-top: 15px;
}

/* End Main Nav
--------------------------------------------------------------------------------------------------------*/

/* =6. Main Content
--------------------------------------------------------------------------------------------------------*/
#background-color {
    background: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 101;
    -webkit-overflow-scrolling: touch;
    top: 0;
    left: 0;
    display: block;
}
.oldie #background-color {
    display: none;
}
.pushed-left #content,
.pushed-left #header,
.pushed-left #footer,
.pushed-left #background-color {
    -webkit-transform: translate3d(360px, 0px, 0px);
    transform: translate3d(360px, 0px, 0px);
}
.pushed-left-alt #content,
.pushed-left-alt #header,
.pushed-left-alt #footer,
.pushed-left-alt #background-color,
.no-csstransforms3d .pushed-left #content,
.no-csstransforms3d .pushed-left #header,
.no-csstransforms3d .pushed-left #footer {
    -webkit-transform: translateX(360px);
    -ms-transform: translateX(360px);
    transform: translateX(360px);
}
.pushed-right,
.pushed-left {
    overflow: hidden;
}
.pushed-right #content,
.pushed-right #header,
.pushed-right #footer,
.pushed-right #background-color {
    -webkit-transform: translate3d(-360px, 0px, 0px);
    transform: translate3d(-360px, 0px, 0px);
}
.pushed-right-alt #content,
.pushed-right-alt #header,
.pushed-right-alt #footer,
.pushed-right-alt #background-color,
.no-csstransforms3d .pushed-right #content,
.no-csstransforms3d .pushed-right #header,
.no-csstransforms3d .pushed-right #footer {
    -webkit-transform: translateX(-360px);
    -ms-transform: translateX(-360px);
    transform: translateX(-360px);
}

#content,
#header,
#footer,
#background-color {
    -webkit-transition: -webkit-transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
    transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
}
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 360px;
    z-index: 101;
    height: 100%;
    background: #fff;
    padding: 30px 15px 0 15px;
}
.btn-primary {
    font-weight: 700;
}

.pushed-right div#content {
    height: 100vh;
    bottom: 0;
    position: fixed;
}
.menu-item {
    margin: 8px auto;
    button.btn-success {
        margin: auto;
    }
    .item-description {
        white-space: pre-line;
    }
}
header#shopping-cart {
    left: auto;
    right: 0;
    overflow: auto;
    padding: 0;
    div#empty-cart {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        height: 100%;
    }
    ul.option-set {
        padding: 0 15px;
        text-align: left;
        li:last-child .menu-item {
            border-bottom: none;
        }
    }
    .menu-item {
        margin: 0 0 15px 0;
        padding-bottom: 20px;
        border-bottom: 1px solid #d1d1d1;
        border-radius: 0;
        button.btn-success {
            width: 100%;
        }
        h5{
            margin: 0 0 5px 0;
            span {
                margin-right: 10px;
            }
        }
    }
    .btn-primary {
        width: 100%;
        border-radius: 0;
        margin-top: 10px;
        img {
            width: 28px;
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            margin-left: 8px;
        }
    }
    .total-price {
        position: fixed;
        bottom: 0;
        width: 360px;
        background: rgb(244, 244, 244);
        padding-top: 10px;
        z-index: 2;

        &:before {
            content: '';
            width: 100%;
            height: 70px;
            top: -70px;
            left: 0;
            z-index: 2;
            position: absolute;
            background: -moz-linear-gradient(
                top,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 1%,
                rgba(255, 255, 255, 1) 100%
            ); /* FF3.6-15 */
            background: -webkit-linear-gradient(
                top,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 1%,
                rgba(255, 255, 255, 1) 100%
            ); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 1%,
                rgba(255, 255, 255, 1) 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f4f4f4',GradientType=0 ); /* IE6-9 */
        }
        svg {
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            width: 26px;
            margin-left: 5px;
        }
    }
}
h5.main-total-price {
    font-size: 18px;
    color: #007bfe;
    text-align: center;
    width: 100%;
    font-weight: 400;
}

figure {
    margin: 0;
}
#content {
    position: absolute;
    width: 100%;
    z-index: 102;
    // background: #F6F6F6;
}
.blog-overview .container {
    padding-top: 65px;
}
.gallery .container {
    padding-top: 40px;
}
#white-background {
    background: #fff;
    width: 360px;
    position: fixed;
    height: 100%;
    z-index: 98;
}
#shadow {
    background: #242423;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    -webkit-overflow-scrolling: touch;
    top: 0;
    display: block;
}
#shadow {
    -webkit-transition: opacity 0.2s ease 0s;
    transition: opacity 0.2s ease 0s;
}

.pushed-right #shadow,
.pushed-right-alt #shadow,
.no-csstransforms3d .pushed-right #shadow {
    opacity: 0.5;
    z-index: 1001;
}

#container {
    background: #2f2f2e;
}
#container.no-bg {
    background: none;
}

.container {
    position: relative;
    margin: 0 auto;
}
.section {
    position: relative;
    padding: 77px 0 77px 0;
    display: block;
    z-index: 2;
}
.header-section {
    padding: 0;
    text-align: center;
    &-content {
        display: flex;
        align-items: center;
        height: 100%;
    }
    :before {
        content: '';
        display: inline-block;
    }
    .title-cat {
        display: flex;
        align-items: stretch;
        h1 {
            display: inline-block;
            font-size: 28px;
            font-weight: 700;
            margin: 0 0 0 10px;
            color: #fff;
        }
    }
}

.overlay {
    display: block;
    position: absolute;
    top: 0;
    background-color: rgba(47, 47, 46, 0.5);
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}
.text-muted.error {
    color: #ff0a0a !important;
}
.client-info {
    .form-check {
        display: inline-block;
        margin-right: 30px;
    }
    label.form-label {
        display: block;
    }
}

span.btn-price {
    margin-left: 10px;
}

.desc-item {
    background: #fff;
    cursor: pointer;
    border-radius: 13px;
    padding: 15px 15px 25px 15px;;
    p {
        margin-bottom: 8px;
        white-space: pre-line;
    }
    span.price {
        font-weight: 700;
    }
    span.view-more {
        display: inline-block;
        text-align: center;
        float: right;
        border: 1px solid #00B57A;
        color: #00B57A;
        padding: 5px 10px;
        border-radius: 10px;
    }
}

/* =End Main Content
--------------------------------------------------------------------------------------------------------*/

/* =7. Column Grid
--------------------------------------------------------------------------------------------------------*/

.overflow {
    overflow: hidden;
}

.info-box {
    position: absolute;
    right: 20px;
    bottom: 20px;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}
.info-box.centered {
    width: 100%;
    left: 0;
    bottom: 20px;
    top: auto;
}
.info-box h3,
.info-box h4,
.info-box h5 {
    margin: 0px 0 0 0;
    padding: 15px 20px;
    color: #fff !important;
    display: inline-block;
}
.info-box.no-link h3,
.info-box.no-link h4,
.info-box.no-link h5,
.info-box.no-link p,
.info-box.no-overlay h3,
.info-box.no-overlay h4,
.info-box.no-overlay h5,
.info-box.no-overlay p {
    background: #3b3b3b;
    padding: 6px 10px 8px !important;
}
a:hover .info-box h3,
a:hover .info-box h4,
a:hover .info-box h5 {
    color: #fff !important;
}
.info-box p a,
a .info-box p,
a:hover .info-box p,
.info-box p.small {
    color: #fff !important;
    border: 0;
    margin: 0 !important;
}
.info-box h3 span.small,
.info-box h4 span.small,
.info-box h5 span.small {
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    padding-top: 3px;
    text-transform: uppercase;
    line-height: 1.25;
    font-style: normal !important;
    letter-spacing: 1px;
}

.margin-wrapper {
    margin: 0px;
    position: relative;
}
.covering-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(47, 47, 46, 0.3);
    border: 0;
}
.covering-image.hidden-default {
    opacity: 0;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}
a:hover .covering-image.hidden-default {
    opacity: 1;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}
a:hover .covering-image {
    background: rgba(46, 46, 46, 0.5);
}
.info-box-content {
    height: 100%;
    width: 100%;
}
.info-box-content .parent {
    height: 100%;
    width: 100%;
    padding: 0px;
}
.covering-image h2,
.covering-image h3,
.covering-image p {
    color: #fff !important;
    background: none !important;
    display: inline-block;
    padding: 0px;
    margin: 0;
    margin-bottom: 5px;
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    white-space: nowrap;
    position: relative;
}
.covering-image p {
    display: block;
    opacity: 0.7;
}
a:hover .covering-image h2,
a:hover .covering-image h3,
a:hover .covering-image p {
    color: #fff !important;
}
a:hover .covering-image h2,
a:hover .covering-image h3 {
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    margin-bottom: 10px;
}
/* =End Column Grid
--------------------------------------------------------------------------------------------------------*/

/* =8. Content Elements
--------------------------------------------------------------------------------------------------------*/

.border-left-wrapper {
    padding-left: 25px;
}

.borderline {
    background: #d8d8d8;
    width: 100%;
    height: 1px;
    display: block;
    position: relative;
    margin: 25px 0 20px 0;
}

/* =End Content Elements
--------------------------------------------------------------------------------------------------------*/

/* =9. Icons
--------------------------------------------------------------------------------------------------------*/
.social-list {
    padding-bottom: 10px;
    margin: 0;
    cursor: default;
    font-size: 0;
}
.social-list li {
    display: inline-block;
    padding: 0 5px;
}
.social-list li a i {
    color: #3b3b3b;
    font-size: 18px;
}
.more-info .social-list {
    padding-top: 5px;
    padding-bottom: 0;
}
.more-info .social-list li a i {
    font-size: 17px;
}
i.fa {
    margin-right: 3px;
    color: #3b3b3b;
}
.covering-image i.fa {
    color: #fff;
}
/* End Icons
--------------------------------------------------------------------------------------------------------*/

/* =10. ../Images/theme
--------------------------------------------------------------------------------------------------------*/
.images {
    display: block;
    position: relative;
}
img {
    width: auto;
    max-width: 100%;
    z-index: 9;
    box-shadow: #fff 0 0 0;
}

/* End ../Images/theme
--------------------------------------------------------------------------------------------------------*/

/* =11. Footer
--------------------------------------------------------------------------------------------------------*/
footer {
    margin: 0;
    padding: 0;
    width: auto;
    position: relative;
    text-align: center;
    background: #f0f0f0;
    z-index: 200;
    i.fa.fa-map-marker {
        margin-left: 15px;
    }
    .fa-heart {
        color: #ff152f;
    }
}
footer div {
    display: inline-block;
    text-align: center;
    padding: 27px 18px 20px;
}
footer p {
    font-size: 12px;
    display: inline-block;
    margin-right: 10px;
}
.copyright {
    background: white;
    width: 100%;
    padding: 10px 18px 10px;
}
.footer-menu a {
    margin-right: 10px;
    text-decoration: underline;
    color: #a2a2a2;
}
/* End Footer
--------------------------------------------------------------------------------------------------------*/
/* =12. Lists
--------------------------------------------------------------------------------------------------------*/
.contact-list li i {
    left: 1px;
    line-height: 1.5;
    position: absolute;
}
.contact-list li i.fa-envelope {
    font-size: 13px;
    margin-top: 1px;
}
.contact-list li i.fa-paper-plane {
    font-size: 13px;
    margin-top: 1px;
}
.contact-list li i.fa-map-marker {
    font-size: 16px;
    left: 4px;
    margin-top: -1px;
}
.contact-list li i.fa-phone {
    font-size: 15px;
    left: 2px;
}
.contact-list li {
    padding-left: 25px;
    position: relative;
    line-height: 1.5;
    font-size: 13px;
    padding-bottom: 5px;
}
ul.contact-list li a {
    color: #3b3b3b;
}
ul.contact-list li a:hover {
    color: #3b3b3b;
}
.products-list {
    justify-content: space-between;
    .menu-item {
        margin: 15px 0;
        padding: 0;
        border: 1px solid #D5D5D5;
        background: #fff;
        cursor: pointer;
        border-radius: 13px;
        & > .row > .col-lg-12.col-md-12.col-sm-12 {
            padding: 0;
        }
        .img-items {
            padding: 0 0 0px 15px;
            border-radius: 13px 0 0 13px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .content-items {
            padding-top: 7px;
        }
        &:hover {
            box-shadow: 2px 2px 20px #00000024;
        }
    }
}
.item-row {
    flex: 0 0 46%;
    max-width: 46%;
}

.products-list .menu-item .img-items img {
    border-radius: 13px 0 0 13px;
}
/* =End Lists
--------------------------------------------------------------------------------------------------------*/

/* =13. Googlemaps
--------------------------------------------------------------------------------------------------------*/
#map {
    width: 100%;
    margin: 0;
    border: 0;
}
#map:before,
#map:after {
    background: none;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
#map:after {
    bottom: 0;
    top: auto;
}
/* End Googlemaps
--------------------------------------------------------------------------------------------------------*
/* =15. Btn
--------------------------------------------------------------------------------------------------------*/
.btn-success {
    color: #fff;
    background-color: var(--success);
    border-color: var(--success);
    i.fa {
        color: #fff;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    &:hover i.fa,
    &:active i.fa,
    &:focus i.fa,
    &:visited i.fa {
        color: #fff;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}
button.btn-back {
    -webkit-appearance: none;
    background: none;
    border: none;
    display: inline-block;
}
.btn-danger {
    color: #ff152f;
    background-color: #ffffff;
}
/* End Btn
--------------------------------------------------------------------------------------------------------*/

/* =16. Modal
--------------------------------------------------------------------------------------------------------*/
.modal-content {
    .modal-header {
        padding: 1rem 1rem 0rem 1rem;
        border-bottom: none;
        position: absolute;
        z-index: 3;
        width: 100%;
        .close {
            z-index: 1;
            background: #7b7b7b;
            opacity: 1;
            color: #fff !important;
            padding: 5px 10px;
            margin: -8px -7px auto auto;
            border-radius: 5px;
            span {
                color: #fff !important;
            }
        }
    }
    .modal-body {
        padding: 0;
        .header-section img {
            width: 100%;
        }
        h2.title-rest-select {
            text-align: center;
            padding: 30px 0;
            letter-spacing: 0;
            font-size: 1.5rem;
        }
        .rest-select {
            text-align: center;
            button.btn-primary {
                background: transparent;
                border-color: #007bff;
                color: #007bff;
                border: 1px solid;
                font-weight: 400;
                margin-bottom: 20px;
                &:hover, &:active, &:focus{
                    color: #fff !important;
                }
            }
            &.show {
                button.btn-primary {
                    color:#fff;
                }
            }
        }
        .rest-btn {
            border: none;
            justify-content: center;
        }
        .btn-container-whatsapp {
            position: fixed;
            bottom: 3.5em;
            max-width: 480px;
            left: 0;
            right: 0;
            margin: auto;
        }
        .card {
            border: none;
            .collapse, .collapsing {
                padding-top: 25px;
            }
            h5 {
                display: inline-block;
                margin-bottom: 10px;
            }
            h5.center {
                text-align: center;
                width: 100%;
            }
            .card-body {
                padding: 0 20px;
                /*height: auto;*/
                .btn-back g#Back-ico {
                    stroke: #3b3b3b;
                }
                .container {
                    padding: 0;
                }
                .other-layout {
                    margin-bottom: 30px;
                }
                .collapse.show.card-body {
                    background: #f3f9ff;
                    margin-bottom: 20px;
                    margin-top: -10px;
                }
                input#order-form-userAddressReferences {
                    margin-top: 20px;
                }
                ul.totals {
                    border-top: 1px solid #d8d8d8;
                    margin-top: 20px;
                    padding-top: 20px;
                    h5 {
                        text-align: right !important;
                    }
                    li {
                        span {
                            width: 50%;
                            display: inline-block;
                        }
                        span.total-label {
                            text-align: left;
                        }
                    }
                }

                .footer-note {
                    margin-top: 10px;
                }
                p {
                    font-size: 14px;
                    color: #5d5d5d;
                    margin-bottom: 10px;
                    padding-top: 20px;
                }
                select[name='userSinpeBank'] {
                    margin-bottom: 20px;
                }

                .ios,
                .android {
                    width: 100%;
                    height: 223px;
                    background-size: 100%;
                    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.2);
                    margin: 20px 0 30px 0;
                    position: relative;
                }
                .ios {
                    background-image: url(/images/ios.png);
                }
                .android {
                    background-image: url(/images/android.png);
                }

                span.important-info {
                    text-align: center;
                    display: block;
                    text-transform: uppercase;
                    font-weight: 700;
                    padding-top: 5px !important;
                }
                p.important-desc {
                    text-align: center;
                    padding-top: 5px !important;
                }
                a#open-sms,
                a#cancel-order {
                    text-align: center;
                    display: inline-block;
                    font-size: 12px;
                    color: #8d8d8d;
                    margin: 30px 0 20px 0;
                    span {
                        color: #007aff;
                        text-decoration: underline;
                    }
                }
                figure.icon-wa {
                    text-align: center;
                    img {
                        width: 50%;
                    }
                }
                figure.img-sinpe {
                    margin: 15px 0;
                    text-align: center;
                }
                p.note-wa {
                    text-align: center;
                    margin-bottom: 30px !important;
                    font-size: 19px;
                }
                .user-info {
                    padding-left: 0;
                    .fa {
                        padding-top: 12px;
                        width: 12%;
                        display: inline-block;
                        margin-right: 0;
                        vertical-align: top;
                    }
                    .form-group {
                        margin-bottom: 1rem;
                        display: inline-block;
                        width: 87%;
                        small {
                            font-size: 65%;
                            margin-top: 0;
                        }
                        .form-control {
                            border: none;
                            border-radius: 0;
                            border-bottom: 1px solid #ced4da;
                            box-shadow: none;
                            -webkit-appearance: none;
                            padding-left: 0;
                            background-color: #fff !important;

                            &:hover,
                            &:focus,
                            &:active {
                                border-bottom: 1px solid #037afe;
                                background-color: #fff !important;
                            }
                        }
                        input:-internal-autofill-selected {
                            background-color: #fff !important;
                        }
                        .form-label {
                            border-bottom: 1px solid #ced4da;
                            padding-left: 10px;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }
                .user-details {
                    margin-top: -10px;
                }
                .disabled {
                    opacity: 0.4;
                    input#order-form-userAddress:hover {
                        border-bottom: 1px solid #ced4da;
                    }
                }
                .img-map {
                    padding-left: 10px;
                    img {
                        height: 162px;
                        padding-bottom: 26px;
                    }
                    img.is-disabled {
                        opacity: 0.5;
                    }
                    .inputs-container {
                        margin-top: -10px;
                        .fa {
                            padding-top: 12px;
                            width: 13% !important;
                        }
                        .fa-credit-card,
                        .fa-money {
                            font-size: 12px;
                            padding-top: 14px;
                        }
                        .form-label {
                            padding: 0.375rem 0;
                        }
                    }
                }
                .inputs-container {
                    height: 54px;
                }
                .disable {
                    opacity: 0.4;
                }
                .footer-note {
                    small {
                        text-align: justify;
                        margin: 0 17px;
                        line-height: 20px;
                        font-size: 12px;
                        padding-bottom: 10px;
                    }
                }
                button.btn-back {
                    margin: 0 10px 0 0;
                    svg{
                        width: 25px;
                        margin-top: -4px;
                    }
                }
                .selectable-row {
                    .userPaymentMethod.form-group {
                        z-index: 3;
                        position: relative;
                    }
                }
                .selectable-row.next {
                    &:before {
                        right: 0;
                        top: 29%;
                        transform: rotate(270deg);
                    }
                }
                div.userPaymentMethod {
                    &:before {
                        top: 16% !important;
                    }
                }
                #map {
                    height: 28vh;
                }
            }
        }
        .add-to-cart {
            padding: 0;
            .btn-primary img {
                transform: rotate(180deg);
                margin-left: 8px;
                width: 25px;
                margin-right: 10px;
            }
            li {
                text-align: right;
            }
            .nopadding {
                padding: 0;
            }
            .cont-list-items {
                padding: 0;
                li.items-list-checkout {
                    display: flex;
                }
            }
            .btn-container {
                position: absolute;
                text-align: center;
                margin: auto;
                left: 0;
                right: 0;
                button.btn-success {
                    width: 100%;
                }
                > div {
                    padding: 0;
                }
            }
            .select-payment {
                &:before {
                    top: 31%;
                    transform: rotate(270deg);
                }
                i.fa {
                    width: 7%;
                    display: inline-block;
                    margin: 0;
                }
                .form-group {
                    width: 93%;
                    display: inline-block;
                    margin-bottom: 0;
                    .form-label {
                        margin: 10px 0;
                    }
                }
            }
            .quantity-content {
                margin-bottom: 100px;
            }
            .btn-actions-modal {
                position: fixed;
                bottom: 3.5em;
                background: #fff;
                z-index: 2;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 498px;
                padding: 0 30px;
                &:before{
                    content: '';
                    width: 100%;
                    height: 70px;
                    top: -70px;
                    left: 0;
                    z-index: 2;
                    position: absolute;
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, white 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f4f4f4',GradientType=0 );
                }
                button.btn {
                    width: 100%;
                    display: inline-block;
                }
                button.btn.btn-outline-danger {
                    margin-bottom: 15px;
                }
            }
        }
        p {
            margin-bottom: 0;
            white-space: pre-line;
        }
        .ins-section {
            margin-top: 30px;
            textarea.form-control {
                background: none;
            }
        }
        .quantity-item {
            margin: 30px auto 60px auto;
            .input-group-prepend {
                .btn-outline-secondary {
                    border-radius: 20px 0 0 20px;
                    border-right: none;
                }
            }
            .input-group-apoend {
                .btn-outline-secondary {
                    border-radius: 0 20px 20px 0;
                    border-left: none;
                }
            }
            button.btn-outline-secondary {
                border: none;
                padding: 1.25px 0.75rem;
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    background-color: transparent;
                    color: #2eb32c;
                    box-shadow: none !important;
                }
                span.controls {
                    font-size: 22px;
                }
            }
            input.form-control {
                background-color: #fff !important;
                border: none;
            }
            .input-group.mb-3 {
                border: 1px solid #d1d1d1;
                border-radius: 20px;
            }
        }
        .client-info {
            margin-top: 15px;
            width: 100%;
            input.form-control {
                width: 100%;
            }
            > .row {
                margin-bottom: 18px;
            }
            .radio {
                display: inline-block;
                margin-right: 40px;
                input[type='radio'] {
                    margin-right: 5px;
                }
            }
        }
    }
    .modal-body.modal-full {
        height: calc(100vh - 3.5rem);
        overflow-y: scroll;
    }
}

// 17. Modifiers
.modifiers {
    .form-check-label{
        margin-left: 3px;
    }
    .checked span {
        color: #29a847;
    }

    input[type="checkbox"], input[type="radio"] {
        width: auto;
        height: 100%;
        appearance: none;
        vertical-align: middle;
        padding: 20px 10px 20px 0;
        float: left;
        position: relative;
        border: none;

        &:checked {
            &::after {
                content: " ";
                display: block;
                width: 4px;
                height: 9px;
                border: solid #29a847;
                border-width: 0 1px 1px 0;
                position: absolute;
                left: 5px;
                top: 2px;
                margin-top: 0;
                transform: rotate(45deg);
            }
            + .form-check-label{
                color: #29a847;
            }
            // ~ .form-check-label{
            //     background: #29a847;
            // }

        }

        &::before {
            content: " ";
            display: block;
            border: solid 1px #3b3b3b;
            border-radius: .8em;
            height: 15px;
            width: 15px;
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 0px;
            opacity: 1;

        }
        &:checked::before {
            content: " ";
            border: solid 1px #29a847;

        }
    }
    input.form-check-input[disabled]:before {
        border: solid 1px #bababa;
    }
    input.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label, .disabled span {
        color: #bababa;
    }
}

.ins-section {
    textarea.form-control {
        background: none;
    }
}

.btn-container button {
    width: 90%;
    margin: auto;
}

.ins-section {
    textarea.form-control {
        background: none;
    }
}

.btn-container button {
    width: 90%;
    margin: auto;
}

select {
    -webkit-appearance: none;
}

.form-control:focus {
    border-color: #ced4da;
    box-shadow: none;
}

.selectable-row,
.select-sinpe {
    position: relative;
    &:before {
        content: '\f107';
        position: absolute;
        top: 39%;
        right: 15px;
        z-index: 2;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #ced4da;
        font-size: 21px;
    }
}
.select-sinpe {
    &:before {
        top: 28%;
    }
}

.header-order {
    display: none;
}

p.intro-label {
    margin-bottom: 15px;
    text-align: justify;
    font-size: 14px;
}
.gmap {
    margin: 0 -20px;
}
.map-address {
    margin: 15px 0;
}
.save-address {
    margin-bottom: 1rem;
    img {
        width: 28px;
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        margin-left: 8px;
    }
}
span.price-modal {
    display: inline-block;
    width: 25%;
}
span.name-modal {
    text-align: left;
    width: 75%;
    display: inline-block;
}
span.quantity-modal {
    width: 15%;
    display: inline-block;
    text-align: left;
}
h5.marg-top {
    margin-top: 5px;
}
.other-input {
    margin-bottom: 20px;
}

button.btn-primary {
    background: #007bff;
    border-color: #007bff;
    &:hover, &:active, &:focus{
        background: #086bd6 !important;
        border-color: #086bd6 !important;
    }
}

.sold_out{
    opacity: 0.5;
    span.status-label {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        align-self: center;
        border: 1px solid #ff162f;
        text-align: center;
        border-radius: 16px;
        margin: 0px 10px 9px 10px;
        padding: 2px 10px;
        color: #ff162f;
        float: right;
    }
}
.select-payment,
.other-input {
    .card > div.card-header {
        border: 1px solid #ced4da;
        border-radius: 10px;
        background: #fff;
        margin-bottom: 10px;
        color: #5d5d5d;
        padding: 0;
        .collapsing {
            margin-bottom: 20px;
        }
        .form-check {
            padding-left: 0;
            input[type='radio'] {
                position: absolute;
                top: 12px;
                left: 2rem;
                -webkit-appearance: none;
                border: 1px solid #757575;
                height: 15px;
                width: 15px;
                border-radius: 50%;
            }
            .form-check-label {
                width: 100%;
                padding: 0.75rem 2.25rem;
                margin-bottom: 0 !important;
            }
        }
    }
    .card div.active-header {
        border: 1px solid #007bff;
        background: #fff;
        border-radius: 10px !important;
        color: #007bff;
        z-index: 2;
        input[type='radio'] {
            background: #007bff;
            box-shadow: inset 0px 0px 0px 2px white;
            border: 1px solid #007bff !important;
        }
    }
    .card-body {
        margin-top: -17px !important;
        z-index: 1;
    }
}
/* End Modal
--------------------------------------------------------------------------------------------------------*/
.accepted-card {
    height: 25px;
}

.status-restaurant {
    top: 60px;
}
.status-restaurant p {
    color: #fff;
    text-align: center;
    font-weight: bold;
    margin: 0;
    font-size: 14px;
}
