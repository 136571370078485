@charset "utf-8";

/* Large Devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {
    #menu-button #nav-button:hover {
        width: 60px;
        padding: 15px 15px 0 25px;
        -webkit-transition: all 0.2s ease 0s;
        transition: all 0.2s ease 0s;
    }
    .only-lg {
        display: none;
    }
    .centered-md {
        float: none;
        display: inline-block;
        margin: 40px 5px 20px 5px;
    }
    .centered-md {
        text-align: center;
    }
}

/* Landscape Ipad */
@media only screen and (max-width: 1024px) {
    a .covering-image h2 {
        font-size: 22px;
        margin-top: 10px;
    }
    div.wrapper-digitalmenu {
        margin-top: 0 !important;
    }
}

/* Medium Devices (desktops and laptops, 992px and below) */
@media only screen and (max-width: 992px) {
    #menu-button #nav-button:hover {
        width: 60px;
        padding: 15px 15px 0 25px;
        -webkit-transition: all 0.2s ease 0s;
        transition: all 0.2s ease 0s;
    }
    .col-lg-12 .client {
        width: 33.33333%;
    }

    .comment-reply-button-column a {
        opacity: 1;
    }
    .comment-content {
        max-width: 50%;
    }
}

/* Mobile Portrait Size to Mobile Landscape Size (tablets and smartphones, 767px and below) */
@media only screen and (max-width: 767px) {
    .wrapper {
        margin-top: 100px;
    }
    #headline_cycler ul.flexslider > li > h1,
    #headline h1,
    h1 {
        font-size: 40px;
    }

    .col-lg-3 .alignright {
        float: none;
        padding-top: 10px;
    }
    .project-pagination .col-lg-3 .alignright {
        float: right;
        padding-top: 0px;
    }

    .blog-author-picture {
        float: none;
        margin: 0 auto;
        padding: 0;
        width: 75%;
    }
    .blog-author-description {
        width: auto;
        padding-top: 20px;
        padding-right: 0;
        text-align: center;
    }
    .comment,
    .comment-count {
        text-align: center;
    }
    .commenter-avatar {
        padding: 0;
        margin: 0 auto;
        width: 150px;
        display: inline-block;
        float: none;
    }
    .comment-content {
        padding-top: 20px;
        display: block;
        width: 100%;
        max-width: 100%;
    }
    .comment.reply.level-1 {
        padding-left: 0;
    }
    .comment-reply-button-column {
        float: none;
    }
    .comment-form-icon-wrapper {
        position: relative;
        top: 0;
        left: 0;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .comment-form-icon-wrapper:before {
        display: inline-block;
    }
    .comment-form {
        padding: 0;
    }
    .comment-form > input,
    .comment-form > textarea {
        width: 100%;
    }

    .border-left-wrapper {
        padding-left: 15px;
        padding-top: 25px;
    }
    .terms-cond h3 {
        font-size: 18px;
    }
    .modal-body .card .card-body .img-map img {
        margin-bottom: 0;
    }
    div.wrapper-digitalmenu section.section-digitalmenu .full-width section.item-digitalmenu {
        border-bottom: 1px solid #cacaca;
    }
    .products-list:before {
        display: none;
    }
    div.wrapper-digitalmenu section.section-digitalmenu .categories-nav {
        display: flex;
    }
}

/* Mobile Landscape Size to Tablet Portrait (smartphones, 480px and below) */
@media only screen and (max-width: 480px) {

    .products-list {
        .menu-item {
            background: #f6f6f6;
            .row{
                grid-template-columns: 1fr;
                display: grid;
                margin-left: 0;
                padding: 0;
            }
            .show-img {
                grid-template-columns: 110px 1fr;
            }
            .img-items {
                flex: auto;
                max-width: 100%;
                padding: 0;
                img {
                    width: auto;
                    height: 73px;
                }
            }
            .content-items {
                padding-right: 0;
                padding-top: 0;
                flex: auto;
                max-width: 100%;
                display: grid;
                align-items: end;
                align-self: center;
                padding-right: 15px;
                grid-template-columns: repeat(2, 1fr);
                h5{
                    font-size: 17px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 2;
                    padding-right: 5px;
                }
            }
        }
    }
    .card-body > button.btn-back > img {
        width: 28px;
        margin-top: -4px;
    }
    .modal-content .modal-body.modal-full {
        height: calc(100vh - 1rem);
    }

    div.nav{
        .content-header-title {
            float: left;
            width: 89%;
            .header-title{
                padding-top: 14px;
                width: 83%;
                padding: 0;
                overflow: hidden;
                position: relative;
                display: inline-block;
                margin: 3px 5px 0 5px;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;

            }
            button.btn-back{
                height: 30px;
                float: left;
                svg {
                    // margin-top: -17px;
                }
            }
        }
    }


    div.wrapper {
        -webkit-overflow-scrolling: touch;
        height: auto;
    }
    .col-lg-12 .client {
        width: 50%;
    }

    h1 {
        font-size: 40px;
        letter-spacing: 0;
    }
    h2,
    .element h2 {
        font-size: 36px;
    }
    h3 {
        font-size: 30px;
    }

    footer .alignleft,
    footer .alignright {
        float: none;
    }
    .add-padding {
        padding: 0;
    }
    .bordered-content {
        padding: 30px 35px 30px;
    }
    .bordered-content.more-info {
        padding-top: 25px;
    }

    article .margin-wrapper,
    article .article-heading {
        width: 100% !important;
        margin-bottom: 0px;
        padding: 0px;
    }
    article .article-heading {
        padding-bottom: 30px !important;
    }

    .grey-xs {
        background: #f5f5f5 !important;
    }

    .child {
        padding: 0;
    }

    .popup-content {
        padding: 25px;
    }

    .margin-wrapper .button {
        display: none;
    }
    .left-corner-wrapper {
        z-index: 1000;
        position: relative;
    }
    .right-corner-wrapper {
        z-index: 1000;
        top: 0;
        margin-top: 14px;
    }
    h1#logo {
        display: inline-block;
        position: relative;
        left: auto;
        top: auto;
        margin: 0;
        background: none;
        text-align: center;
        padding: 5px 15px !important;
        float: left;
        a {
            display: inline-block;
            margin: 0 auto;
        }
    }
    #social-header .social-list {
        text-align: center;
        width: 100%;
    }

    #nav-button{
        padding: 18px 5px 0 5px;
        &:hover {
            padding: 18px 5px 0 5px;
        }
    }

    .section.header-section,
    .centralizing-wrapper {
        height: 240px;
    }

    .section.header-section {
        background-position: right 0 top 70px;
        background-size: contain;
    }

    .section {
        padding: 1px 0;
        &.section.item-content{
            padding-top: 0;
        }
    }

    .intro {
        height: 260px;
    }

    .centered-md .alignright.button {
        margin-top: 0 !important;
        margin-bottom: 5px;
    }

    .custom-pagination .left,
    .custom-pagination .right {
        clear: both;
        display: block;
        width: 100%;
    }
    #shopping-cart-button {
        #nav-button:hover {
            opacity: 1;
        }
    }

    footer {
        div {
            padding: 20px 18px 20px;
        }
        p {
            margin: 0;
        }
    }

    .products-list {
        justify-content: space-between;
        margin-top: 25px;
        &:before {
            display: none;
        }
        .item-row {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .menu-item {
        width: 100%;
        .row {
            padding-bottom: 20px;
        }
        .price-btn {
            margin-top: 10px;
        }
    }
    .item-list-view {
        .menu-item {
            margin: 0 0px 20px;
        }
    }
    .item-modal {
        .menu-item {
            button.btn-success {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    .item-details {
        .menu-item {
            p {
                margin: 0 0 1.5rem 0;
                text-align: center;
                white-space: pre-line;
            }
            form.mt-4{
                margin-top: 0 !important;
            }
        }
    }
    .icon img {
        height: 70px;
        width: auto;
    }
    a .covering-image h2 {
        font-size: 24px;
    }

    .quantity-item {
        width: 50%;
    }
    header#shopping-cart {
        width: 260px;
        padding: 30px 0px 0 0px;
        div#empty-cart {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            height: 100%;
            p {
                text-align: center;
                padding: 0 10px;
            }
        }
        .menu-item {
            padding: 0 0 25px 0;
            margin: 0 0 30px 0;
            .row{
                grid-template-columns: 1fr;
                display: grid;
                margin-left: 0;
                padding: 0;
                ul {
                    display: block;
                }
                strong {
                    font-weight: 300;
                    font-style: italic;
                    margin-top: 10px;
                    display: inline-block;
                    color:#000;
                }
                .mt-2 span {
                    font-weight: 700;
                }
            }
        }
        p {
            text-align: left;
        }
    }
    div.pushed-left #content,
    div.pushed-left #header,
    div.pushed-left #footer,
    div.pushed-left #background-color {
        -webkit-transform: translate3d(260px, 0px, 0px);
        transform: translate3d(260px, 0px, 0px);
    }
    div.pushed-left-alt #content,
    div.pushed-left-alt #header,
    div.pushed-left-alt #footer,
    div.pushed-left-alt #background-color,
    .no-csstransforms3d div.pushed-left #content,
    .no-csstransforms3d div.pushed-left #header,
    .no-csstransforms3d div.pushed-left #footer {
        -webkit-transform: translateX(260px);
        -ms-transform: translateX(260px);
        transform: translateX(260px);
    }

    .pushed-right,
    div.pushed-left {
        overflow: hidden;
    }
    .pushed-right #content,
    .pushed-right #header,
    .pushed-right #footer,
    .pushed-right #background-color {
        -webkit-transform: translate3d(-260px, 0px, 0px);
        transform: translate3d(-260px, 0px, 0px);
    }
    .pushed-right-alt #content,
    .pushed-right-alt #header,
    .pushed-right-alt #footer,
    .pushed-right-alt #background-color,
    .no-csstransforms3d .pushed-right #content,
    .no-csstransforms3d .pushed-right #header,
    .no-csstransforms3d .pushed-right #footer {
        -webkit-transform: translateX(-260px);
        -ms-transform: translateX(-260px);
        transform: translateX(-260px);
    }
    header#shopping-cart .total-price {
        width: 260px;
        z-index: 1;
    }
    ul.option-set {
        padding: 0 15px;
    }
    .btn-lg {
        font-size: 1rem;
    }
    .content-category .covering-image {
        background: rgba(0, 0, 0, 0.4);
    }
    // #content {
    //     background: #fff;
    // }
    label {
        margin-bottom: 0.5rem !important;
    }
    header {
        width: 260px;
        right: 0;
        left: auto;
        text-align: center;
        h4 {
            color: #e60000;
            font-weight: 700;
            font-size: 20px;
        }
        img {
            width: 100px;
        }
    }
    .input-group.mb-3 {
        margin-bottom: 0 !important;
    }
    .user-info {
        .fa {
            width: 17% !important;
        }
        .form-group {
            width: 83% !important;
        }
    }
    .modal-body .card .card-body div.img-map {
        img {
            height: 162px;
            padding-bottom: 1rem;
        }
        i.fa-credit-card,
        i.fa-money {
            padding-top: 14px !important;
        }
        i.fa.fa-mobile {
            padding-top: 10px;
        }
    }

    div.address-inputs {
        margin-bottom: 60px;
        div.inputs-container {
            height: auto !important;
        }
    }
    .section.header-section {
        background-position: right top !important;
        padding: 0 !important;
    }
    .section.header-item {
        height: 153px;
        img{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
    // section.item-content{
    //     background: #fff;
    // }
    .ins-section {
        margin-top: 20px;
    }
    div.btn-container {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 3;
        background: #fff;
        &:before {
            content: '';
            width: 100%;
            height: 40px;
            top: -40px;
            left: 0;
            z-index: 2;
            position: absolute;
            background: -moz-linear-gradient(
                top,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 1%,
                rgba(255, 255, 255, 1) 100%
            ); /* FF3.6-15 */
            background: -webkit-linear-gradient(
                top,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 1%,
                rgba(255, 255, 255, 1) 100%
            ); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0) 1%,
                rgba(255, 255, 255, 1) 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f4f4f4',GradientType=0 ); /* IE6-9 */
        }

        button.btn-success {
            width: 92% !important;
            margin: auto;
            &:disabled{
                background: #fff;
                color: var(--success);
            }
        }
        button.btn.btn-danger.btn-lg.btn-block.btn-cart {
            position: absolute;
            left: 5%;
            bottom: 15px;
            width: 90%;
        }
        .sold_out span.status-label{
            margin: 4px 10px 0 10px;
        }
    }
    .modal-content {
        .modal-body {
            .add-to-cart {
                .btn-actions-modal {
                    width: 90%;
                    bottom: 28px;
                    padding: 0 15px 15px 15px;
                    button.btn.btn-outline-danger {
                        margin-bottom: 10px;
                    }
                }
                .btn-container-whatsapp {
                    bottom: 1em;
                }
            }
            .save-address {
                position: fixed;
                bottom: 0px;
                width: 92%;
                left: 15px;
            }

        }
    }
}
@media only screen and (max-width: 320px) {
    .modal-body .quantity-item input.form-control {
        padding: 0.375rem 0.4rem;
    }
    .modal-content .modal-body .add-to-cart .btn-container-whatsapp {
        z-index: 2;
        background: white;
        width: 94%;
        padding-bottom: 1em;
        bottom: 0.5em;
    }
    .modal-content .modal-body .card .card-body ul.totals {
        padding-bottom: 50px;
    }
    .section.header-section,
    .centralizing-wrapper {
        height: 200px;
    }
    a .covering-image h2 {
        font-size: 21px;
    }

    h5 {
        font-size: 16px;
    }
    .products-list .menu-item .content-items {
        padding-left: 10px;
    }
}

@media only screen and (min-width: 481px) {
    .products-list {
        & > [class*="col-"] {
            padding-right: 5px;
            padding-left: 5px;
        }
    }

}
