.menu-item {
    background-clip: border-box;
    border-radius: 0.25rem;
    padding: 15px;
    margin: 8px;

    span.price {
        font-size: 18px;
    }
    h5 {
        font-weight: 700;
        margin: 0;
        display: inline-block;
    }
    button.btn-success {
        width: auto;
    }
    .price-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.btn-container {
    padding: 15px 0;
}

.add-to-cart {
    textarea {
        resize: none;
    }
}

.icon img {
    height: 94px;
    width: auto;
}
.images {
    overflow: hidden;
    img {
        width: 100%;
        height: auto;
        border: 2px solid #000;
        box-shadow: none;
        border-top: none;
    }
}

.content-category {
    .covering-image {
        background: rgba(0, 0, 0, 0.6);
    }
    a:hover .covering-image {
        background: rgba(0, 0, 0, 0.4);
    }
}
