$primary: var(--primary);
$secondary: var(--secondary);
$success: var(--success);
$info: var(--info);
$warning: var(--warning);
$danger: var(--danger);
$light: var(--light);
$dark: var(--dark);
$backgroundColor: var(--backgroundColor);
$headerColor: var(--headerColor);

$theme-colors: (
  'primary': var(--primary),
  'secondary': var(--secondary),
  'success': var(--success),
  'info': var(--info),
  'warning': var(--warning),
  'danger': var(--danger),
  'light': var(--light),
  'dark': var(--dark)
);
